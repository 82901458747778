// 集采方式-文本枚举
export const groupBuyTypeText = {
  1: {text:"即报即采", bg: "bg-17_28"},
  2: {text:"汇集采购", bg: "bg-f7_fe"}
}
// 集采状态-价格预警
export const groupBuyPriceColor = {
  // 集采状态_价格预警
  1: "car-e1",
  2: "car-02",
  3: "car-6b"
}
// 集采状态-文字
export const groupBuyStateText = {
  1: "集采中",
  2: "集采完成",
}