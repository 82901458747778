//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import env from "@/../env.js";
export default {
  data: function() {
    return {
      img_addr: "https://shigongbang.obs.cn-east-3.myhuaweicloud.com/"
    };
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  components: {},
  methods: {
    // 超过十万的部分处理为省略号
    handlePrice(price) {
      price = parseInt(price) < price ? price.toFixed(2) : price;
      return price < 1000000 ? price : price.toString().substring(0, 5) + "...";
    },
    //跳转商品详情
    toProductDetail(item) {
      if (item.item_cid1 !== 7) {
        //普通商品
        window.open(
          `${env[process.env.environment].VUE_APP_MATERIALSURL}/shopdetails/${
            item.commodity
          }?skuId=${item.id}`
        );
        return;
      }
      let title = item.pro.cartTitle;
      // 截取强度等级名称
      let strongName = title.substring(title.indexOf(" ") + 1, title.length);
      window.open(
        `${
          env[process.env.environment].VUE_APP_MATERIALSURL
        }/CommercialConcrete/${item.commodity}/${strongName}`
      );
    }
  },
  created() {},
  mounted() {}
};
