//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import apiLogistics from "@/api/apiLogistics";
import env from "../../../../../../env";
import { getShipTimeStr } from "@/filters/index.js";
import comFindSke from "../../skeComponents/comFindSke.vue";
export default {
  name: "",
  components: {
    notData: () => import("../../noData"),
    FooterLoad: () => import("@/components/FooterLoad/index.vue"),
    comFindSke
  },
  props: {},
  data() {
    return {
      isLoadData: false,
      isClose: false,
      isMore: false,
      isEmpty: false,
      loadingStep: false,
      list: [],
      menuList: [],
      currentPage: 1,
      comLogo: require("@/assets/company_wuliu_icon.png"),
      singleLogo: require("@/assets/chengyun_icon.png"),
      teamLogo: require("@/assets/chengyun_icon.png")
    };
  },
  computed: {},
  watch: {},
  filters: {
    getCarData(item) {
      let { carModelName, carLenName, heightHurdle, isDump } = item;
      let str = "";
      if (carModelName) {
        str += carModelName.split(",").join("/");
      }
      if (carLenName) {
        str += "/" + carLenName.split(",").join("/");
      }
      if (heightHurdle) {
        str += "/" + "栏高" + heightHurdle + "米";
      }
      if (isDump || isDump == 0) {
        str += isDump == 1 ? "/自卸" : isDump == 0 ? "/非自卸" : "";
      }
      return str;
    }
  },
  created() {
    this.refreshData();
  },
  mounted() {},
  async fetch() {
    const resMenu = await apiLogistics.goodsType({
      areacode: ""
    });
    const { data, code } = resMenu.data;
    if (+code === 200) {
      const list =
        data &&
        data.map(item => {
          return {
            name: item.name,
            value: item.no
          };
        });
      this.menuList = [{ name: "全部", value: "" }, ...list];
    }
  },
  methods: {
    clickTabItem(item) {
      const { name, value } = item;
      this.searchType = name === "全部" ? [] : [value];
      this.refreshData();
    },
    defaultLogo(data) {
      const { terminal } = data;
      if (+terminal === 1) {
        return this.comLogo;
      }
      if (+terminal === 2) {
        return this.teamLogo;
      }
      if (+terminal === 3) {
        return this.singleLogo;
      }
    },
    // 进入企业
    toDetail(item) {
      const { no, pattern } = item;
      let url = "";
      if (pattern === 0) {
        url = `${
          env[process.env.environment].VUE_APP_LOGISTICSURL
        }/CargoDetails?CargoId=${no}&typeOf=1`;
      } else {
        url = `${
          env[process.env.environment].VUE_APP_LOGISTICSURL
        }/CargoDetailsPrice?CargoId=${no}&typeOf=1`;
      }
      window.open(url);
    },
    // 刷新
    refreshData() {
      this.isClose = false;
      this.currentPage = 1;
      this.list = [];
      this.getGoodsList();
    },
    // 加载
    loadData() {
      if (this.isLoadData) {
        this.currentPage += 1;
        this.getGoodsList();
        this.isClose = true;
        return;
      }
    },
    // 获取货物列表
    async getGoodsList() {
      this.loadingStep = true;
      const parmas = {
        businessType: 1,
        carLenNos: [],
        carModelNos: [],
        cityNo: "",
        destCityNo: "",
        goodsModeNo: "",
        limit: 6,
        loadingTime: 0,
        maxWeight: "",
        minWeight: "",
        page: this.currentPage,
        pattern: 1,
        radius: "",
        shipmentTime: "",
        goodsModeNo: this.searchType ? this.searchType[0] : ""
      };
      const res = await apiLogistics.goodsSourceList(parmas);
      const { data, code, msg } = res.data;
      if (+code === 200) {
        const list =
          (data &&
            data.records.length &&
            data.records.map(item => {
              return {
                ...item,
                _localtion: `${item.cityName}${item.districtName}-${item.destCityName}${item.destDistrictName}`
              };
            })) ||
          [];
        this.isLoadData = data.records.length === parmas.limit;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.isMore = this.currentPage < data.pages;
        this.loadingStep = false;
        this.$nextTick(() => {
          this.updateSticky();
        });
        return;
      }
      this.$nextTick(() => {
        this.updateSticky();
      });
      this.loadingStep = false;
    },
    updateSticky() {
      setTimeout(() => {
        this.$emit("updateSticky");
      }, 3000);
    },
    changeCargoInfo(item) {
      // 展示末级货物类型 v2.4.0
      let goodsName = item.secondGoodsTypeName
        ? item.secondGoodsTypeName
        : item.goodsModeName
        ? item.goodsModeName
        : item.goodsTypeName
        ? item.goodsTypeName
        : '';
      let str = '';
      if (item.weight > 0 && item.volume > 0) {
        str = goodsName + '/' + item.weight + '吨' + `/${item.volume}方`;
      } else if (!item.weight && item.volume > 0) {
        str = goodsName + '/' + item.volume + '方';
      } else if (item.weight == null && item.volume == null) {
        str = goodsName;
      } else {
        str = goodsName + '/' + item.weight + '吨';
      }
      if (item.packingModeName) {
        str += '/' + item.packingModeName;
      }
      // 搜索结果列表 和 货运大厅列表接口返回的 装卸方式 的字段不一致，做兼容处理
      const handleName = item.handleName || item.handleTypeName;
      if (handleName) {
        str += '/' + handleName;
      }
      return str;
    },
  }
};
