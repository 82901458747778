import localStorage from "@/storage/localStorage";
import store from "@/store";
import { lazyAMapApiLoaderInstance } from "@vuemap/vue-amap";
// 综合定位，优先取store、localStorage中缓存的定位信息，没有再获取高德定位，高德定位失败则获取百度定位
// const def_pos = {
//   lng:87.68,lat:43.77
// }
// const def_pos = {
//   lng: 87.617729,s
//   lat: 43.792816,
//   address: { province: "新疆维吾尔自治区" },
// };
const def_pos = {
  lng: 116.405289,
  lat: 39.904987,
  address: { province: "北京" }
};
export function getPosition() {
  return new Promise(async (resolve, reject) => {
    if (!window.sessionStorage.getItem("isUseCurrent")) {
      let position = null;
        try {
          const AMap = AMap || window.AMap;
          position = await initGaodeMap();
          if (position && position.lng && position.lat) {
            savePpostion(position)
            return resolve(position);
          } else {
            savePpostion(def_pos)
            return resolve(def_pos);
          }
        } catch (error) {
          savePpostion(def_pos)
          return resolve(def_pos);
        }
    }

    let position = store().state.position || null;
    // let position = null;
    let localStoragePos =
      position && position.lng && position.lat
        ? null
        : localStorage.read("positionHome");
    position = localStoragePos ? JSON.parse(localStoragePos) : position;

    if (position && position.lng && position.lat) {
      savePpostion(position)
      return resolve(position);
    } else {
      position = await initGaodeMap();
      if (position && position.lng && position.lat) {
        savePpostion(position)
        return resolve(position);
      } else {
        savePpostion(def_pos)
        return resolve(def_pos);
      }
    }
  });
}
// 高德地图定位
export function initGaodeMap() {
  return new Promise((resolve, reject) => {
    lazyAMapApiLoaderInstance
      .then(res => {
        AMap.plugin("AMap.Geolocation", function() {
          let geolocation = new AMap.Geolocation({
            enableHighAccuracy: true, // 是否使用高精度定位，默认:true
            timeout: 10000 // 超过10秒后停止定位，默认：5s
          });
          geolocation.getCityInfo(function(status, result) {
            if (status === "complete") {
              // 获得成功
              let position = {
                lng: result.position[0],
                lat: result.position[1],
                address: {
                  province: result.province,
                  city: result.city
                }
              };
              store().commit("SET_CURRENTIPADDRESS", true);
              window.sessionStorage.setItem(
                "currentIpAddress",
                JSON.stringify(position)
              );
              return resolve(position);
            } else {
              // savePpostion(def_pos);
              return resolve(def_pos);
              // initBdMap();
            }
          });
        });
      })
      .catch(error => {
        console.log("lazyAMapApiLoaderInstance", error);
        return resolve(position);
      });
  });
}
// 百度地图定位
export function initBdMap() {
  return new Promise((resolve, reject) => {
    let geolocation = new BMap.Geolocation();
    geolocation.getCurrentPosition(function(r) {
      if (this.getStatus() === BMAP_STATUS_SUCCESS) {
        // 获得成功
        let position = {
          lng: r.point.lng,
          lat: r.point.lat,
          address: {
            province: r.address.province,
            city: r.address.city,
            district: r.address.district,
            street: r.address.street,
            streetNumber: r.address.street_number
          }
        };
        savePpostion(position);
        resolve(position);
      } else {
        reject();
        savePpostion(def_pos);
      }
    });
  });
}
// 保存定位的信息到store中和localStorage中
function savePpostion(position) {
  store().commit("SET_POSITION", position);
  if(!localStorage.read("positionHome")){
    localStorage.set("positionHome", JSON.stringify(position));
  } 
}

