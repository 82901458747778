//
//
//
//
//
//
//
//
//
//
//
//

import { tabListEnum } from './constants'
import stickybits from "stickybits"
import FindVehiles from './pages/findVehiles'
import FindGoods from './pages/findGoods'
import FindFreight from './pages/findFreight'
import FindTransport from './pages/findTransport'
export default {
    name: 'flowModel',
    components: {
        commonTab: () => import('../commonTag'),
        FindVehiles,
        FindGoods,
        FindFreight,
        FindTransport,
    },
    props: {

    },
    data() {
        return {
            tabList: tabListEnum,
            tabIndex: 0,
            allComponents:['FindVehiles','FindFreight','FindGoods','FindTransport'],
            scrolldata: {}
        }
    },
    computed: {

    },
    watch: {

    },
    created() {},
    mounted() {
        this.$nextTick(() => {
          // this.initSticky()
        })
    },
    methods: {
        initSticky() {
          this.scrolldata = stickybits(".scroll", {
                useFixed: true,
                useStickyClasses: true,
            })
        },
        updateSticky() {
          return
          this.scrolldata.update()
        }
    },
};
