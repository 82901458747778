//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import apiLogistics from "@/api/apiLogistics";
import env from "../../../../../../env";
import { getShipTimeStr } from "@/filters/index.js";
import comFindSke from "../../skeComponents/comFindSke.vue";
export default {
  name: "FindFreight",
  components: {
    notData: () => import("../../noData"),
    FooterLoad: () => import("@/components/FooterLoad/index.vue"),
    comFindSke
  },
  props: {},
  data() {
    return {
      menuList: [],
      isLoadData: false,
      isClose: false,
      isMore: false,
      isEmpty: false,
      loadingStep: false,
      list: [],
      currentPage: 1,
      singleLogo: require("@/assets/chengyun_icon.png")
    };
  },
  computed: {},
  watch: {},
  filters: {
    formatFreightUnit1(value) {
      let str = "";
      switch (value) {
        case 1:
          str = "元/趟";
          break;
        case 2:
          str = "元/吨";
          break;
        case 3:
          str = "元/方";
          break;
        default:
          str = "";
          break;
      }
      return str;
    },
    datanumber2datetime(datetime, fmtdata) {
      if (!datetime) return "/";
      function padLeftZero(str) {
        return ("00" + str).substr(str.length);
      }
      if (datetime === "" || datetime === null) {
        return "";
      }
      const date = new Date(datetime);
      let fmt = fmtdata && fmtdata !== "" ? fmtdata : "yyyy-MM-dd";
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      const o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds()
      };
      for (const k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          const str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : padLeftZero(str)
          );
        }
      }
      return fmt;
    }
  },
  created() {
    this.refreshData();
  },
  mounted() {},
  async fetch() {
    const resMenu = await apiLogistics.carType({
      areacode: ""
    });
    const { data: d, code: c, msg: m } = resMenu.data;
    if (+c === 200) {
      const list =
        d &&
        d.map(item => {
          return {
            name: item.name,
            value: item.no
          };
        });
      this.menuList = [{ name: "全部", value: "" }, ...list];
    }
  },
  methods: {
    /* 时间戳转换 */
    Mixin_time(time) {
      let date = new Date(time);
      let year = date.getFullYear();
      let month = date.getMonth() + 1; // js从0开始取
      let date1 = date.getDate();
      return year + '-' + month + '-' + date1;
    },
    clickTabItem(item) {
      const { name, value } = item;
      this.searchType = name === "全部" ? [] : [value];
      this.refreshData();
    },
    getCarData(item) {
      let { carTypeName, carLenName, heightHurdle, isDump } = item;
      let str = "";
      if (carTypeName) {
        str += carTypeName.split(",").join("/");
      }
      if (carLenName) {
        str += "/" + carLenName.split(",").join("/");
      }
      if (heightHurdle) {
        str += "/" + "栏高" + heightHurdle + "米";
      }
      if (isDump || isDump == 0) {
        str += isDump == 1 ? "/自卸" : isDump == 0 ? "/非自卸" : "";
      }
      return str;
    },
    // 进入企业
    toDetail(item) {
      const { no } = item;
      window.open(
        `${
          env[process.env.environment].VUE_APP_LOGISTICSURL
        }/transportDetails?CargoId=${no}&typeOf=1`
      );
    },
    // 刷新
    refreshData() {
      this.isClose = false;
      this.currentPage = 1;
      this.list = [];
      this.getFreightList();
    },
    // 加载
    loadData() {
      if (this.isLoadData) {
        this.currentPage += 1;
        this.getFreightList();
        this.isClose = true;
        return;
      }
    },
    // 获取货物列表
    async getFreightList() {
      this.loadingStep = true;
      let searchType = this.searchType;
      const parmas = {
        areacode: "",
        carLenNos: [],
        carTypeNos: this.searchType || [],
        cityNo: "",
        destCityNo: "",
        limit: 6,
        page: this.currentPage,
        pattern: 0,
        radius: "",
        timeSort: 1
      };
      const res = await apiLogistics.search(parmas);
      const { data, code, msg } = res.data;
      if (+code === 200) {
        const list =
          (data &&
            data.records.length &&
            data.records.map(item => {
              return {
                ...item,
                _localtion: `${item.cityName}${item.districtName}-${item.destCityName}${item.destDistrictName}`
              };
            })) ||
          [];
        this.isLoadData = data.records.length === parmas.limit;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.isMore = this.currentPage < data.pages;
        this.loadingStep = false;
        this.$nextTick(() => {
          this.updateSticky();
        });
        return;
      }
      this.$nextTick(() => {
        this.updateSticky();
      });
      this.loadingStep = false;
    },
    updateSticky() {
      setTimeout(() => {
        this.$emit("updateSticky");
      }, 3000);
    }
  }
};
