//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { apiHome } from "@/api/apiHome.js";
import { apiInquiryPrice } from "@/api/apiInquiryPrice";
import { apiResSearch } from "@/api/apiResSearch";
import localStorage from "@/storage/localStorage.js";
import cookie from "@/storage/cookies.js";
import env from "../../../../../env";
import { httpMap } from "@/api/httpMapPositiom.js";
import { getPosition } from "@/pages/Home/components/goods/mapPosition.js";
import NoData from "@/pages/Home/components/noData.vue";
import CommonTag from "../commonTag";
import GoodsCell from "@/pages/Home/components/goods/components/goodsCell/indexCell.vue";

import FooterLoad from "@/components/FooterLoad/index.vue";
import TabList from "@/components/TabList/index.vue";
import EnquiryCell from "@/pages/Home/components/goods/components/enquiryCell/enquiryCell.vue";
import SaleCell from "@/pages/Home/components/goods/components/salesCell/saleCell.vue";

import SupplierCell from "@/pages/Home/components/goods/components/supplierCell/supplierCell.vue";
import GroupBuyingCell from "@/pages/Home/components/goods/components/groupBuyingCell/indexCell.vue";
import Auction from "@/pages/Home/components/tender/ItemCell/auction.vue";
import comTMBSke from "../skeComponents/comTMBSke.vue";
import comSmallTBSke from "../skeComponents/comSmallTBSke.vue";
import comLCRSke from "../skeComponents/comLCRSke.vue";
import supermarke from './components/supermarke/index.vue'
import { AppConfig } from "@/config";
export default {
  components: {
    GoodsCell,
    CommonTag,
    FooterLoad,
    TabList,
    NoData,
    EnquiryCell,
    SaleCell,
    SupplierCell,
    GroupBuyingCell,
    Auction,
    comTMBSke,
    comSmallTBSke,
    comLCRSke,
    supermarke,
    // AppConfig
    //pages/Home/components/goods/components/salesCell/saleCell.vue
  },
  computed: {
    getIpAddres() {
      let isHasIpAddress = this.$store.state.isHasIpAddress;
      let currentIpAddress =
        JSON.parse(window.sessionStorage.getItem("currentIpAddress")) || {};
      let { lat, lng } = currentIpAddress;
      return {
        isHasIpAddress,
        lat,
        lng
      };
    },
    
    goData() {
      if (this.currentMenuIndex === 2) {
        let url = env[process.env.environment].VUE_APP_WEBSITE
        return { text: '经营帮超市', url: `${url}company/${AppConfig.sysBaseConf.supermarkeCompanyId}?no=${AppConfig.sysBaseConf.supermarkeComponList[0]}`, isSupermarke: true}
      } else {
        return { text: '商品材料', url: 'VUE_APP_MATERIALSURL',}
      }
    }
  },
  data() {
    return {
      currentMenuIndex: 0,
      isClose: false,
      isMore: false,
      isLoadData: false,
      load: true,
      list: [],
      typeList: [],
      firstType: "",
      firstTypeName: "全部",
      pageSize: 1,
      currentArea: {},
      position: {},
      isEmpty: false,
      AppConfig
    };
  },
  created() {
    this.getCategoryList();
    if (process.client) {
      this.refreshData();
    }
  },

  mounted() { },
  watch: {
    // "getIpAddres": function(newval, oldval) {
    //   console.log("newval, oldval", newval, oldval);
    //   let {isHasIpAddress, lat, lng} = newval
    //   if(isHasIpAddress){
    //      this.getList(lat, lng);
    //   }
    //   // let l;
    //   // let currentIpAddress =
    //   //   JSON.parse(window.sessionStorage.getItem("currentIpAddress")) || {};
    //   // console.log("currentIpAddress", currentIpAddress);
    //   // let { lat, lng } = currentIpAddress;
    //   // this.getList(lat, lng);
    // }
  },
  methods: {
    auctionDetail(item) {
      const url = env[process.env.environment].VUE_APP_TENDERURL;
      // 拍卖大厅
      const { no } = item;
      window.open(`${url}/auctionDetail?no=${no}`);
    },
    changeTag(index) {
      this.isEmpty = false;
      this.currentMenuIndex = index;
      this.typeList = [];
      this.firstTypeName = "全部";
      this.$emit("changeTag", index);
      ![5, 7].includes(this.currentMenuIndex) && this.getCategoryList();
      this.currentMenuIndex == 5 && this.getAuctionType();
      this.currentMenuIndex === 1 && this.getGoodsCatList();
      // this.currentMenuIndex === 2 && this.getSupermarketList(); // 经营帮超市
      this.refreshData();
    },
    firstTabChange(item) {
      this.isEmpty = false;
      this.firstType = item.value;
      this.firstTypeName = item.name;
      this.currentPage = 1;
      this.isClose = false;
      this.isMore = false;
      this.load = true;
      this.list = [];
      [0, 1, 4].includes(this.currentMenuIndex) && this.requestList();
      // [0, 2, 3, 4].includes(this.currentMenuIndex) && this.requestList();
      this.currentMenuIndex === 3 && this.getGroupSaleList();
      this.currentMenuIndex === 6 && this.getProcurementList();
      this.currentMenuIndex === 7 && this.getSupplierList();
      this.currentMenuIndex === 5 && this.getAuctionList();
      this.currentMenuIndex === 2 && this.getSupermarketList(); // 经营帮超市
    },
    // 经营帮超市
    async getSupermarketList() {
      this.position = this.currentArea = cookie.cookieRead("area")
        ? JSON.parse(cookie.cookieRead("area"))
        : {};
      const pramas = {
        page: this.currentPage,
        limit: 12,
        asc: false,
        sortType: 3,
        item_provinceId: "",
        lon: +this.position.lng,
        lat: +this.position.lat,
        firstCategoryId: this.firstType,
        companyNos: AppConfig.sysBaseConf.supermarkeComponList, // 查询对应的企业商品信息
        sortType: 1
      };
      let { isHasIpAddress, lat, lng } = this.getIpAddres
      if (isHasIpAddress) {
        pramas.lon = lng;
        pramas.lat = lat;
      }
      let res = await apiHome.getcommoditysHall(pramas);
      console.log('resresres', res)
      const { data, code, msg, totalPages = 0 } = res.data;
      if (+code === 0) {
        const list = data || [];
        this.isLoadData = list.length === 12;
        this.list = this.list.concat(list);
        this.isMore = this.currentPage < totalPages;
        this.isEmpty = this.list.length == 0;
        this.load = false;
        return;
      }
      this.load = false;

      // getcommoditysHall
    },
    // 收起
    refreshData() {
      this.currentPage = 1;
      this.isClose = false;
      this.isMore = false;
      this.list = [];
      this.firstType = "";
      this.load = true;
      // this.$bus.$emit("scrollCurent", { index: 0, name: "自营商品" });
      [0, 1, 4].includes(this.currentMenuIndex) && this.requestList();
      this.currentMenuIndex === 3 && this.getGroupSaleList();
      this.currentMenuIndex === 6 && this.getProcurementList();
      this.currentMenuIndex === 7 && this.getSupplierList();
      this.currentMenuIndex === 5 && this.getAuctionList();
      this.currentMenuIndex === 2 && this.getSupermarketList(); // 经营帮超市
    },
    // 加载更多数据
    loadMore() {
      if (this.isLoadData) {
        this.currentPage += 1;
        this.isClose = true;
        [0, 1, 4].includes(this.currentMenuIndex) && this.requestList();
        this.currentMenuIndex === 3 && this.getGroupSaleList();
        this.currentMenuIndex === 6 && this.getProcurementList();
        this.currentMenuIndex === 7 && this.getSupplierList();
        this.currentMenuIndex === 5 && this.getAuctionList();
        this.currentMenuIndex === 2 && this.getSupermarketList(); // 经营帮超市
      }
    },
    // 经营帮超市 跳转企业店铺
    gotoDetail(item){
      // VUE_APP_WEBSITE
      const url = env[process.env.environment].VUE_APP_MATERIALSURL;
      console.log('item', item)
      window.open(`${url}/shopdetails/${item.itemSku.itemId}?skuId=${item.goodsId}&type=${item.itemtype}&isCenter=false&jumpUrl=shopdetail&fcId=${item.item_cid1}`)
    },
    // 需求大厅详情
    toDetail(item) {
      const url = env[process.env.environment].VUE_APP_MATERIALSURL;
      if (this.firstTypeName !== "商砼") {
        window.open(
          `${url}/shopdetails/${item.commodity}?skuId=${item.id}&type=1&jumpUrl=shopdetail`
        );
        return;
      }
      window.open(
        `${url}/CommercialConcrete/${item.id}/${item.strongLevel}?type=1`
      );
    },
    // 拍卖大厅筛选
    async getAuctionType() {
      // let res = await apiHome.getAuctionStatus();
      // const { data, code, msg } = res.data;
      // if (+code == 0) {
      //   const list =
      //     data &&
      //     data.map(item => {
      //       return {
      //         value: item.no,
      //         name: item.name
      //       };
      //     });
      //   this.typeList = list;
      //   return;
      // }
      setTimeout(() => {
        this.typeList = [
          { name: "商品拍卖", value: 1 },
          { name: "资产处置", value: 2 },
          { name: "司法拍卖", value: 3 },
          { name: "破产拍卖", value: 4 },
          { name: "海关/政府", value: 5 }
        ];
      });
      return;
    },

    // 拍卖大厅
    async getAuctionList() {
      // /ov1/auction/list
      let currentArea = cookie.cookieRead("area")
        ? JSON.parse(cookie.cookieRead("area"))
        : "";
      let params = {
        limit: 12,
        page: this.currentPage,
        sortMethod: 1,
        state: this.firstType
      };
      let res = await apiHome.getAuctionList(params);
      console.log("resresresres111", res);
      const { result, code, msg } = res.data;
      if (+code === 0) {
        const list = result || [];
        console.log('list.length', list.length)
        this.isLoadData = list.length === params.limit;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.isMore = this.currentPage < res.data.totalPages;
        this.load = false;
        this.isEmpty = this.list.length == 0;
        return;
      }
      this.load = false;
    },
    // 获取商品类型列表
    async getGoodsCatList() {
      let res = await apiInquiryPrice.getProducts();
      const { code, data } = res.data;
      if (+code === 200) {
        const list = data.map(item => {
          return {
            value: item.id,
            name: item.name
          };
        });
        this.typeList = [{ name: "全部", value: "" }, ...list];
        return;
      }
    },
    // 获取供方列表
    async getGroupSaleList() {
      this.load = true;
      const parmas = {
        provinceNo: "",
        cityNo: "",
        status: "",
        firstType: this.firstType,
        searchKey: "",
        current: this.currentPage,
        limit: 6
      };
      const res = await apiResSearch.getGroupSaleList(parmas);
      const { data, code, msg } = res.data;
      if (+code === 200) {
        let list = data.records || [];
        list =
          data.records &&
          list.map(item => ({
            ...item,
            detailList: JSON.parse(item.details)
          }));
        console.log(list);
        this.isLoadData = list.length === parmas.limit;
        this.list = this.list.concat(list);
        this.isMore = this.currentPage < data.totalPage;
        this.isEmpty = this.list.length === 0;
        this.load = false;
        return;
      }
      this.load = false;
    },
    // 请求数据统一处理
    requestList() {
      // 商砼
      const isShang = this.firstTypeName === "商砼";
      isShang ? this.getconcreteList() : this.getList();
    },
    // 获取一级分类
    async getCategoryList() {
      let res = await apiHome.getCatListByAreaCode();
      const { code, data } = res.data;
      if (+code === 0) {
        let list = data.map(item => {
          return {
            value: item.id,
            name: item.name
          };
        });
        list = list.filter(item => item.name !== "商砼")
        this.typeList = [{ name: "全部", value: "" }, ...list];
        return;
      }
    },
    // 需求大厅列表
    async getProcurementList() {
      this.load = true;
      let parmas = {
        page: this.currentPage,
        fcatName: this.firstTypeName === "全部" ? "" : this.firstTypeName,
        limit: 6,
        asc: false,
        status: "1",
        sortType: 1
      };
      let res = await apiHome.getProcurementList(parmas);
      const { data, code, msg, totalPages = 0 } = res.data;
      if (+code === 0) {
        const list = data || [];
        this.isLoadData = list.length === parmas.limit;
        this.list = this.list.concat(list);
        this.isMore = this.currentPage < totalPages;
        this.isEmpty = this.list.length === 0;
        this.load = false;
        return;
      }
      this.load = false;
    },
    setAuctionItem(item) {
      // let { state, no } = item;
      // // this.list[]
      // let index = 0;
      // this.list.forEach((item, indexs) => {
      //   if (item.no === no) {
      //     index = indexs;
      //   }
      // });
      // if (state == 1) {
      //   this.$set(this.list[index], "state", 2);
      // } else if (state === 2) {
      //   this.$set(this.list[index], "state", 3);
      // }
      this.list = [];
      this.currentPage = 1;
      this.getAuctionList();
    },
    // 商砼列表
    async getconcreteList() {
      let requestData = {
        asc: true,
        gradation1Price: "0",
        lat: this.position.lat,
        lon: this.position.lng,
        pageNum: this.currentPage,
        pageSize: 12,
        province: "",
        pumpPrice: "0",
        sortType: 3,
        selfSupport: "1"
      };
      let res = await apiHome.getconcreteList(requestData);
      const { code, result } = res.data;
      if (+code === 0) {
        const list = result.list || [];
        const totalPage = result.totalPage;
        this.isLoadData = list.length === 12;
        this.list = this.list.concat(list);
        this.isMore = this.currentPage < totalPage;
        this.load = false;
        this.isEmpty = this.list.length == 0;
        return;
      }
      this.load = false;
    },
    // 获取询价列表
    async getSupplierList() {
      try {
        this.load = true;
        let parmas = {
          longitude: this.position.lng,
          latitude: this.position.lat,
          limit: 6,
          cid1: "",
          cityNo: "",
          companyNo: "",
          current: this.currentPage,
          distance: 10000,
          districtNo: "",
          keyword: "",
          provinceNo: "",
          userNo: "0"
        };
        let res = await apiHome.getSupplierList(parmas);
        const { data, code, msg } = res.data;
        if (+code === 200) {
          const list = data.records || [];
          this.isLoadData = list.length === parmas.limit;
          this.list = this.list.concat(list);
          this.isMore = this.currentPage < data.totalPage;
          this.isEmpty = this.list.length === 0;
          this.load = false;
          return;
        }
        this.load = false;
      } catch (error) { }
    },
    // 获取商品列表
    async getList() {
      this.position = this.currentArea = cookie.cookieRead("area")
        ? JSON.parse(cookie.cookieRead("area"))
        : {};
      const pramas = {
        page: this.currentPage,
        limit: 12,
        asc: true,
        sortType: 3,
        bestSellers: this.currentMenuIndex === 0 ? "1" : "", // 热销商品
        item_provinceId: "",
        item_cid1: this.firstType,
        selfSupportTag: this.currentMenuIndex === 2 ? "1" : "", // 自营1 非自营2
        promoteStatus: this.currentMenuIndex === 4 ? 2 : "", // 促销状态
        lon: +this.position.lng,
        lat: +this.position.lat
      };
      let { isHasIpAddress, lat, lng } = this.getIpAddres
      if (isHasIpAddress) {
        pramas.lon = lng;
        pramas.lat = lat;
      }
      let res = await apiHome.getGoodsByCatId(pramas);
      console.log('resresres', res)
      const { data, code, msg, totalPages = 0 } = res.data;
      if (+code === 0) {
        const list = data || [];
        this.isLoadData = list.length === 12;
        this.list = this.list.concat(list);
        this.isMore = this.currentPage < totalPages;
        this.isEmpty = this.list.length == 0;
        this.load = false;
        return;
      }
      this.load = false;
    }
  }
};
