import { render, staticRenderFns } from "./findGoods.vue?vue&type=template&id=2c1c0ca3&scoped=true&"
import script from "./findGoods.vue?vue&type=script&lang=js&"
export * from "./findGoods.vue?vue&type=script&lang=js&"
import style0 from "./findGoods.vue?vue&type=style&index=0&id=2c1c0ca3&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c1c0ca3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TabList: require('/devcloud/slavespace/slave1-new/workspace/j_V6eVlOYm/src/components/TabList/index.vue').default,Template: require('/devcloud/slavespace/slave1-new/workspace/j_V6eVlOYm/src/components/FilterSearch/template/index.js').default,FooterLoad: require('/devcloud/slavespace/slave1-new/workspace/j_V6eVlOYm/src/components/FooterLoad/index.vue').default})
