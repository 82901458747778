//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { apiHome } from "@/api/apiHome";
import apiLogistics from "@/api/apiLogistics";
import env from "../../../../../../env";
import { imageCutting } from "@/util/util.js";
import TabList from "@/components/TabList/index.vue";
import comTabSke from "../../skeComponents/comTabSke.vue";
import comSmallTBSke from "../../skeComponents/comSmallTBSke.vue";
export default {
  name: "FindVehiles",
  components: {
    TabList,
    notData: () => import("../../noData"),
    FooterLoad: () => import("@/components/FooterLoad/index.vue"),
    comTabSke,
    comSmallTBSke
  },
  props: {},
  data() {
    return {
      menuList: [],
      list: [],
      currentPage: 1,
      isLoadData: false,
      isClose: false,
      isMore: false,
      isEmpty: false,
      loadingStep: false,
      searchType: [],
      defalut_url: require("@/assets/url_empty.png")
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    // this.refreshData();
    // this.getVehicleMenu();
  },
  async fetch() {
    const resMenu = await apiLogistics.carType({
      areacode: ""
    });
    const { data: d, code: c, msg: m } = resMenu.data;
    if (+c === 200) {
      const list =
        d &&
        d.map(item => {
          return {
            name: item.name,
            value: item.no
          };
        });
      this.menuList = [{ name: "全部", value: "" }, ...list];
    }
    const parmas = {
      areacode: "",
      carLenNos: [],
      carTypeNos: this.searchType,
      cityNo: "",
      destCityNo: "",
      limit: 12,
      page: this.currentPage,
      radius: "",
      timeSort: 1
    };
    const res = await apiHome.getCarOwner(parmas);
    const { data, code, msg } = res.data;
    if (+code === 200) {
      const list =
        (data &&
          data.records.length &&
          data.records.map(item => {
            return {
              ...item,
              msg_info: `${item.carLenName}/${item.carTypeName}/${item.weight}吨`,
              contrct_car: item.carNumber,
              contrct_name: item.name,
              contrct_per: item.publishName,
              contrct_company: item.companyName,
              contrct_tel: item.contrctsNumber,
              img_url: item.carImage || this.defalut_url,
              dest_address: `${item.destProvinceName}${item.destCityName}${item.destDistrictName}`,
              local_address: `${item.provinceName}${item.cityName}${item.districtName}`
            };
          })) ||
        [];
      this.isLoadData = data.records.length === parmas.limit;
      this.list = this.list.concat(list);
      this.isEmpty = this.list.length === 0;
      this.isMore = this.currentPage < data.pages;
      this.loadingStep = false;
    }
  },
  methods: {
    imageCutting: imageCutting,
    // 进入企业
    toDetail(item) {
      const { no } = item;
      window.open(
        `${
          env[process.env.environment].VUE_APP_LOGISTICSURL
        }/DriverDetail?id=${no}`
      );
    },
    // 刷新
    refreshData() {
      this.isClose = false;
      this.currentPage = 1;
      this.list = [];
      // this.$bus.$emit("scrollCurent", { index: 5, name: "物流货运" });
      this.getVehicleList();
    },
    // 加载
    loadData() {
      if (this.isLoadData) {
        this.currentPage += 1;
        this.getVehicleList();
        this.isClose = true;
        return;
      }
    },
    clickTabItem(item) {
      const { name, value } = item;
      this.searchType = name === "全部" ? [] : [value];
      this.refreshData();
    },
    // 获取二级菜单
    async getVehicleMenu() {
      const parmas = {
        areacode: ""
      };
      const res = await apiLogistics.carType(parmas);
      const { data, code, msg } = res.data;
      if (+code === 200) {
        let list =
          (data && data.length && [{ name: "全部", no: "" }, ...data]) || [];
        this.menuList = list;
      }
    },
    // 获取车辆列表
    async getVehicleList() {
      this.loadingStep = true;

      const parmas = {
        areacode: "",
        carLenNos: [],
        carTypeNos: this.searchType,
        cityNo: "",
        destCityNo: "",
        limit: 12,
        page: this.currentPage,
        radius: "",
        timeSort: 1
      };
      const res = await apiHome.getCarOwner(parmas);
      const { data, code, msg } = res.data;
      if (+code === 200) {
        const list =
          (data &&
            data.records.length &&
            data.records.map(item => {
              return {
                ...item,
                msg_info: `${item.carLenName}/${item.carTypeName}/${item.weight}吨`,
                contrct_car: item.carNumber,
                contrct_name: item.name,
                contrct_per: item.publishName,
                contrct_company: item.companyName, // 区别个人or企业
                img_url: item.carImage || this.defalut_url,
                dest_address: `${item.destProvinceName}${item.destCityName}${item.destDistrictName}`,
                local_address: `${item.provinceName}${item.cityName}${item.districtName}`
              };
            })) ||
          [];
        this.isLoadData = data.records.length === parmas.limit;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.isMore = this.currentPage < data.pages;
        this.loadingStep = false;
        this.$nextTick(() => {
          this.updateSticky();
        });
        return;
      }
      this.$nextTick(() => {
        this.updateSticky();
      });
      this.loadingStep = false;
    },
    updateSticky() {
      setTimeout(() => {
        this.$emit("updateSticky");
      }, 3000);
    }
  }
};
