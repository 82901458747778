var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-freight"},[(_vm.menuList.length)?[(_vm.menuList.length)?_c('TabList',{attrs:{"tabList":_vm.menuList},on:{"currentItem":_vm.clickTabItem}}):_vm._e()]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"content"},[(_vm.isEmpty)?_c('notData'):_vm._e(),_vm._v(" "),(_vm.list.length)?_vm._l((_vm.list),function(item,index){return _c('div',{key:index,on:{"click":function($event){return _vm.toDetail(item)}}},[_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-header"},[_c('div',{staticClass:"left"},[_c('img',{attrs:{"src":require("@/assets/load_icon.png")}}),_vm._v(" "),_c('span',[_vm._v("\n                "+_vm._s(item.cityName && item.cityName !== "-"
                    ? item.cityName
                    : item.provinceName)+"\n                "+_vm._s(item.districtName)+"\n              ")])]),_vm._v(" "),_c('div',{staticClass:"center"},[_c('p',[_vm._v("约"+_vm._s(_vm._f("formatDistance")(item.distance)))]),_vm._v(" "),_c('img',{attrs:{"src":require("@/assets/load_unload_arrow.png"),"alt":""}})]),_vm._v(" "),_c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require("@/assets/unload_icon.png")}}),_vm._v(" "),_c('span',[_vm._v("\n                "+_vm._s(item.destCityName && item.destCityName !== "-"
                    ? item.destCityName
                    : item.destProvinceName)+"\n                "+_vm._s(item.destDistrictName)+"\n              ")])]),_vm._v(" "),(item.carrierType == 1)?_c('span',{staticClass:"tag",staticStyle:{"background":"linear-gradient(90deg, #2161ea 0%, #36bef9 100%)"}},[_vm._v("找货承运")]):(item.carrierType == 2)?_c('span',{staticClass:"tag",staticStyle:{"background":"linear-gradient(90deg, #f28e26 0%, #f9644f 100%)"}},[_vm._v("竞价找货")]):(item.carrierType == 3)?_c('span',{staticClass:"tag",staticStyle:{"background":"linear-gradient(90deg, #eacda3 0%, #d6ae7b 100%)"}},[_vm._v("返程货运")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"item-main"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"main-cloum"},[_c('span',{staticClass:"label"},[_vm._v("车辆信息：")]),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.getCarData(item)))])]),_vm._v(" "),(item.freight)?_c('p',{staticClass:"text-price"},[_vm._v("\n                意向运费："+_vm._s(item.kilometre ? ((item.kilometre) + "公里内") : "")+_vm._s(item.freight)+_vm._s(_vm._f("formatFreightUnit1")(item.freightUnit))+"\n              ")]):_c('p',{staticClass:"text-price"},[_vm._v("意向运费：面议")])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"main-cloum"},[_c('span',{staticClass:"label"},[_vm._v("可装货时间：")]),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v("\n                  "+_vm._s(_vm._f("getShipTimeStr")(item,item))+"\n                ")])])])]),_vm._v(" "),_c('div',{staticClass:"item-footer"},[_c('img',{attrs:{"src":item.logo || _vm.singleLogo}}),_vm._v(" "),_c('p',[_vm._v("\n              "+_vm._s(item.companyName
                ? item.companyName
                : item.teamName
                ? item.teamName
                : item.publishName)+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"text"},[_vm._v("\n              "+_vm._s(_vm.Mixin_time(item.updateTime))+"\n            ")])])])])}):[(!_vm.isEmpty)?_c('div',{staticClass:"parent"},_vm._l((new Array(6)),function(item){return _c('div',{key:item},[_c('comFindSke')],1)}),0):_vm._e()]],2),_vm._v(" "),_c('FooterLoad',{attrs:{"isClose":_vm.isClose,"isMore":_vm.isMore,"isLoadData":_vm.isLoadData,"menuIndex":4,"goData":{ text: '物流', url: 'VUE_APP_LOGISTICSURL' }},on:{"refreshData":_vm.refreshData,"loadMore":_vm.loadData}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }