var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-goods"},[(_vm.menuList.length)?[(_vm.menuList.length)?_c('TabList',{attrs:{"tabList":_vm.menuList},on:{"currentItem":_vm.clickTabItem}}):_vm._e()]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"content"},[(_vm.isEmpty)?_c('notData'):_vm._e(),_vm._v(" "),(_vm.list.length)?_vm._l((_vm.list),function(item,index){return _c('div',{key:index,on:{"click":function($event){return _vm.toDetail(item)}}},[_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-header"},[_c('div',{staticClass:"left"},[_c('img',{attrs:{"src":require("@/assets/load_icon.png")}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(item.cityName && item.cityName !== "-"
                    ? item.cityName
                    : item.provinceName)+"\n                "+_vm._s(item.districtName))])]),_vm._v(" "),_c('div',{staticClass:"center"},[_c('p',[_vm._v("约"+_vm._s(_vm._f("formatDistance")(item.totalDistance)))]),_vm._v(" "),_c('img',{attrs:{"src":require("@/assets/load_unload_arrow.png"),"alt":""}})]),_vm._v(" "),_c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require("@/assets/unload_icon.png")}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(item.destCityName && item.destCityName !== "-"
                    ? item.destCityName
                    : item.destProvinceName)+"\n                "+_vm._s(item.destDistrictName))])]),_vm._v(" "),_c('span',{staticClass:"tag"},[_vm._v("竞价货源")])]),_vm._v(" "),_c('div',{staticClass:"item-main"},[_c('div',{staticClass:"row"},[_c('div',[_c('div',{staticClass:"main-cloum"},[_c('span',{staticClass:"label"},[_vm._v("货物信息：")]),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.changeCargoInfo(item)))])]),_vm._v(" "),_c('div',{staticClass:"main-cloum"},[_c('span',{staticClass:"label"},[_vm._v("装货时间：")]),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("getShipTimeStr")(item,item)))])]),_vm._v(" "),_c('div',{staticClass:"main-cloum"},[_c('span',{staticClass:"label"},[_vm._v("车辆要求：")]),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("getCarData")(item)))])])])]),_vm._v(" "),_c('div',{staticClass:"row-right"},[_c('div',[_c('div',{staticClass:"right-content"},[(+item.freightType === 1)?_c('div',{staticClass:"text-price"},[_vm._v("\n                    运费：电议\n                  ")]):_c('div',{staticClass:"text-price"},[_vm._v("\n                    运费："+_vm._s(item.allFreight ? item.allFreight + "元" : "")+"\n                  ")])]),_vm._v(" "),_c('div',{staticClass:"right-content"},[_c('span',{staticClass:"label"},[_vm._v("最低报价：")]),_vm._v(" "),_c('span',{staticClass:"text"},[_vm._v("\n                    "+_vm._s(item.lowestPrice ? item.lowestPrice + "元" : "无")+"\n                  ")])]),_vm._v(" "),_c('div',{staticClass:"right-content"},[_c('span',{staticClass:"label"},[_vm._v("报价人数：")]),_vm._v(" "),_c('span',{staticClass:"text"},[_vm._v("\n                    "+_vm._s(item.offerNum ? item.offerNum : "0")+"\n                  ")])])])])]),_vm._v(" "),_c('div',{staticClass:"item-footer"},[_c('img',{attrs:{"src":item.companyLogo || _vm.defaultLogo(item)}}),_vm._v(" "),_c('p',[_vm._v("\n              "+_vm._s(item.terminal == 1
                  ? item.companyName
                  : item.terminal == 2
                  ? item.teamName
                  : item.terminal == 3
                  ? item.contrcts
                  : "无")+"\n            ")])])])])}):[(!_vm.isEmpty)?_c('div',{staticClass:"parent"},_vm._l((new Array(6)),function(item){return _c('div',{key:item},[_c('comFindSke',[_c('template',{slot:"middle"},[_c('div',{staticClass:"middle"},[_c('div',{staticClass:"left"},[_c('el-skeleton-item'),_vm._v(" "),_c('el-skeleton-item')],1),_vm._v(" "),_c('div',{staticClass:"right"},[_c('el-skeleton-item')],1)])])],2)],1)}),0):_vm._e()]],2),_vm._v(" "),_c('FooterLoad',{attrs:{"isClose":_vm.isClose,"isMore":_vm.isMore,"isLoadData":_vm.isLoadData,"menuIndex":4,"goData":{ text: '物流', url: 'VUE_APP_LOGISTICSURL' }},on:{"refreshData":_vm.refreshData,"loadMore":_vm.loadData}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }