//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { imageCutting } from "@/util/util.js";
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    firstTypeName: {
      type: String,
      default: ""
    },
    activeIndex: {
      ype: Number,
      default: 0
    }
  },
  data() {
    return {
      img_addr: "https://shigongbang.obs.cn-east-3.myhuaweicloud.com/"
    };
  },
  created() {},
  filters: {
    setDistance(data) {
      if (data) {
        return data >= 1000
          ? (data / 1000).toFixed(2) + "km"
          : data.toFixed(2) + "m";
      } else {
        return "0.00m";
      }
    }
  },
  computed: {
    isPromote() {
      return this.item.promoteStatus == 2 ? true : false;
    }
  },
  mounted() {},
  methods: {
    imageCutting: imageCutting,
    toDetail(item) {
      this.$emit("toDetail", item);
    },
    getImg(imgs) {
      if (imgs.indexOf(",") != -1) {
        let img = imgs.split(",");
        // 判断是否为http开头的字符串
        if (img[0].indexOf("http") != -1) {
          return imageCutting(img[0], "270", "205", "fill");
        } else {
          return imageCutting(this.img_addr + img[0], "270", "205", "fill");
        }
      } else {
        if (imgs.indexOf("http") != -1) {
          return imageCutting(imgs, "270", "205");
        } else {
          return imageCutting(this.img_addr + imgs, "270", "205", "fill");
        }
      }
    },
    // 超过十万的部分处理为省略号
    handlePrice(price = 0) {
      price = parseInt(price) < price ? price.toFixed(2) : price;
      return price < 1000000 ? price : price.toString().substring(0, 5) + "...";
    }
  }
};
