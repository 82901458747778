//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import env from "@/../env.js";
export default {
  data: function() {
    return {};
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
  },
  components: {},
  created() {},
  mounted() {},
  methods: {
    // 进入企业
    toShop(item) {
      window.open(
        `${env[process.env.environment].VUE_APP_MATERIALSURL}/company/${
          item.companyId
        }?no=${item.companyNo}`
      );
    }
  }
};
