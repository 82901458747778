//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import env from "../../../../../../../env"
import {groupBuyStateText, groupBuyTypeText, groupBuyPriceColor} from "./constants"
export default {
  name: "",
  components: {},
  props: {
    list:{
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      groupBuyStateText,
      groupBuyTypeText,
      groupBuyPriceColor
    };
  },
  computed: {},
  watch: {},
  filters: {
    attrlistFilter(list) {
      if(!list && !list.length) {
        return '无'
      }
      const attrs = list.filter(x => x.value)
      if(attrs.length) {
        let str = ''
        attrs.forEach(el => {
          str += `${el.name}:${el.value}`
        });
        return str
      }
      return '无'
    }
  },
  created() {},
  mounted() {},
  methods: {
    go2Detail(data) {
      const {no} = data
      const jicai = env[process.env.environment].VUE_APP_GROUNPBUYING
      window.open(`${jicai}/factoryDetail/${no}`)
    }
  },
};
